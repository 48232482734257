.home_header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  height: 300px;
  background: var(--black);
  color: var(--white);
}
.home_canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.75;
  filter: contrast(125%);
}
.home_shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, #000000ff 75px, #00000000 150px);
  z-index: 1;
}
.home_logo {
  height: 60px;
  margin-bottom: 5px;
}
.home_logo,
.home_title {
  z-index: 2;
}
